import axiosConfig from "../../../config/axiosConfig";
import { ASSET_URL, PROJECT_URL } from "../../../config/config";
import { createQueryParams } from "../../../utils/helper";

export const GetProjectService = (params) => {
  try {
    const { page, per_page, ordering } = params || {};

    const paramsList = {
      page,
      per_page,
      ordering,
    };

    const queryString = createQueryParams(paramsList);

    const url = `${PROJECT_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const AddProjectService = ({ data, headers }) => {
  return axiosConfig.post(`${PROJECT_URL}/`, data, { headers });
};

export const UpdateProjectService = ({ data, id, headers }) => {
  return axiosConfig.patch(`${PROJECT_URL}/${id}`, data, { headers });
};

export const DeleteProjectService = ({ id }) => {
  return axiosConfig.delete(`${PROJECT_URL}/${id}`);
};

// assets

export const GetAssetService = (params) => {
  try {
    const { page, per_page, project, ordering } = params || {};

    const paramsList = {
      page,
      per_page,
      project,
      ordering,
    };

    const queryString = createQueryParams(paramsList);
    const url = `${ASSET_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const AddAssetService = ({ data, headers }) => {
  return axiosConfig.post(`${ASSET_URL}`, data, { headers });
};

export const UpdateAssetService = ({ data, headers }) => {
  return axiosConfig.patch(`${ASSET_URL}`, data, { headers });
};

export const DeleteAssetService = ({ id }) => {
  return axiosConfig.delete(`${ASSET_URL}/${id}`);
};
