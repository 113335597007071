import { createSlice } from "@reduxjs/toolkit";
import {
  addAsset,
  addProjects,
  deleteAssets,
  deleteProjects,
  getAssets,
  getProjects,
  updateAsset,
  updateProjects,
} from "./projectActions";

export const initialState = {
  isInitialLoad: true,
  loading: false,
  addLoading: false,
  formErrors: {},
  projects: {},
  assetIntialLoad: true,
  assets: {},
  addAssetLoading: false,
  assetFormErrors: {},
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    setAssetInitialLoad: (state, action) => {
      state.assetIntialLoad = action.payload;
    },
    clearProjectData: (state) => {
      state.initialState = true;
      state.loading = false;
      state.addLoading = false;
      state.formErrors = {};
      state.projects = {};
      state.assetIntialLoad = true;
      state.assets = {};
      state.addAssetLoading = false;
      state.assetFormErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // project list
      .addCase(getProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.projects = action.payload?.data;
      })
      .addCase(getProjects.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.projects = {};
      })
      // add new project
      .addCase(addProjects.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addProjects.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(addProjects.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // update project
      .addCase(updateProjects.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateProjects.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(updateProjects.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // delete project
      .addCase(deleteProjects.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteProjects.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(deleteProjects.rejected, (state, action) => {
        state.addLoading = false;
      })
      // assets list
      .addCase(getAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.assetIntialLoad = false;
        state.assets = action.payload;
      })
      .addCase(getAssets.rejected, (state) => {
        state.loading = false;
        state.assetIntialLoad = false;
        state.assets = {};
      })
      // add new project
      .addCase(addAsset.pending, (state) => {
        state.addAssetLoading = true;
      })
      .addCase(addAsset.fulfilled, (state) => {
        state.addAssetLoading = false;
      })
      .addCase(addAsset.rejected, (state, action) => {
        state.addAssetLoading = false;
        state.assetFormErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // update new project
      .addCase(updateAsset.pending, (state) => {
        state.addAssetLoading = true;
      })
      .addCase(updateAsset.fulfilled, (state) => {
        state.addAssetLoading = false;
      })
      .addCase(updateAsset.rejected, (state, action) => {
        state.addAssetLoading = false;
        state.assetFormErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // delete asset
      // delete asset
      .addCase(deleteAssets.pending, (state) => {
        state.addAssetLoading = true;
      })
      .addCase(deleteAssets.fulfilled, (state) => {
        state.addAssetLoading = false;
      })
      .addCase(deleteAssets.rejected, (state, action) => {
        state.addAssetLoading = false;
      });
  },
});

export const {
  setInitialLoad,
  setRoleInitalLoad,
  setAssetInitialLoad,
  clearProjectData,
} = projectSlice.actions;
export default projectSlice.reducer;
