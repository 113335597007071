import {
  AnalyticsIcon,
  HomeIcon,
  InventoryIcon,
  PipelineIcon,
  ReportsIcons,
  SettingsIcon,
  VendorsIcon,
} from "../components/svg";
import { active, in_progress, inactive, pending } from "../constant/constant";

export const modules = {
  home: "home",
  projects: "projects",
  inventory: "inventory",
  vendors: "vendors",
  analytics: "analytics",
  reports: "reports",
  users: "users",
  roles: "roles",
  alerts: "alerts",
  templates: "templates",
  profile: "profile",
  change_password: "changepassword",
  graph: "graphs",
  aircraftmaster: "aircraftmaster",
  enginemaster: "enginemaster",
  aircraftfamily: "aircraftfamily",
  enginefamily: "enginefamily",
};
const {
  home,
  projects,
  inventory,
  vendors,
  analytics,
  reports,
  users,
  roles,
  alerts,
  graph,
  templates,
  profile,
  change_password,
  aircraftmaster,
  enginemaster,
  aircraftfamily,
  enginefamily,
} = modules;

export const permissionsKey = {
  c: "C",
  r: "R",
  u: "U",
  d: "D",
  e: "E",
  i: "I",
  s: "S",
};

export const menuItems = [
  {
    text: "Home",
    icon: HomeIcon,
    path: "/home",
    hasNestedRoutes: true,
    module: [home],
  },
  {
    text: "Projects",
    icon: PipelineIcon,
    path: "/projects",
    module: [projects],
  },
  {
    text: "Inventory",
    icon: InventoryIcon,
    path: "/inventory",
    module: [inventory],
  },
  {
    text: "Vendors Management",
    icon: VendorsIcon,
    path: "/vendors",
    module: [vendors],
  },
  {
    text: "Analytics",
    icon: AnalyticsIcon,
    path: "/analytics",
    module: [analytics],
  },
  {
    text: "Reports",
    icon: ReportsIcons,
    path: "/reports",
    module: [reports],
  },
  {
    text: "Settings",
    icon: SettingsIcon,
    path: "/settings",
    module: [
      users,
      roles,
      alerts,
      templates,
      aircraftmaster,
      enginemaster,
      graph,
      profile,
      change_password,
    ],
  },
];

export const settingsMenuItems = [
  { text: "Users", path: "/settings/users", module: [users] },
  { text: "Roles", path: "/settings/roles", module: [roles] },
  { text: "Alerts", path: "/settings/alerts", module: [alerts] },
  { text: "Template", path: "/settings/template", module: [templates] },
  { text: "Graphs", path: "/settings/graph", module: [graph] },
  {
    text: "Aircraft Family",
    path: "/settings/aircraft-family",
    module: [aircraftfamily],
  },
  {
    text: "Engine Family",
    path: "/settings/engine-family",
    module: [enginefamily],
  },
  {
    text: "Aircraft Master",
    path: "/settings/aircraft-master",
    module: [aircraftmaster],
  },
  {
    text: "Engine Master",
    path: "/settings/engine-master",
    module: [enginemaster],
  },
];

export const profileSettingsMenuItems = [
  { text: "Profile", path: "/settings/profile", module: [profile] },
  {
    text: "Change Password",
    path: "/settings/change-password",
    module: [change_password],
    state: true,
  },
];

export const headerMenuItems = [
  ...profileSettingsMenuItems,

  { text: "Logout" },
];

export const accessOptions = [
  { value: "super_admin", label: "Super Admin" },
  { value: "sc", label: "SC Admin" },
  { value: "read", label: "Read" },
  { value: "write", label: "Write" },
  { value: "admin", label: "Admin" },
];

export const statusOptions = [
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
];

export const graphStatusOptions = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
];

export const exportMenuData = [
  { text: "EXCEL" },
  // { text: "PDF" },
  // { text: "DOC" },
];

export const matchSortKey = {
  created_by: "created_by__name",
  updated_by: "updated_by__name",
  roles: "roles__role_name",
};

export const allowedRoutes = ["/", "/access", "/logout"];

export const openMainLayoutRoutes = ["/access", "/logout"];

export const assetType = [
  { label: "Aircraft ", value: "Aircraft" },
  { label: "Engine", value: "Engine" },
];
export const status = [
  { label: "Inprogress ", value: "Inprogress" },
  { label: "Completed", value: "Completed" },
];

export const importMenuItems = [
  { text: "Append Harvest List", value: "append" },
  { text: "Update Harvest List", value: "update" },
];

export const statusColorLable = [
  { id: 1, label: "Under stock", color: "#CEE741" },
  { id: 2, label: "In stock", color: "#FEF445" },
  { id: 3, label: "Over stock", color: "#F86E00" },
];

export const matchStatusStyle = {
  under_stock: "#CEE741",
  in_stock: "#FEF445",
  over_stock: "#F86E00",
};

export const tooltiPlacemetpProps = {
  placement: "top",
};

export const matchStatusStyleTable = {
  [active]: {
    backgroundColor: "var(--seagreen-color)",
    border: "1px solid var(--emerald-color)",
  },
  [in_progress]: {
    backgroundColor: "var(--tumeric-color)",
    border: "1px solid var(--gold-color)",
  },
  [pending]: {
    backgroundColor: "var(--tumeric-color)",
    border: "1px solid var(--gold-color)",
  },
  [inactive]: {
    backgroundColor: "var(--copper-color)",
    border: "1px solid var(--brown-color)",
  },
};

export const matchStatus = {
  in_progress: "In-Progress",
};

export const repairDropdown = [
  {
    label: "Repair",
    value: "Repair",
  },
  {
    label: "Exchange",
    value: "Exchange",
  },
];
export const inClude = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];
