import { createSlice } from "@reduxjs/toolkit";
import {
  getHarvestList,
  getHarvestListComponents,
  uploadHarvestList,
} from "./harvestListActions";

export const initialState = {
  isInitialLoad: true,
  componentInitialLoad: true,
  loading: false,
  harvestList: {},
  componentList: {},
};

const harvestSlice = createSlice({
  name: "harvest",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    setComponentInitialLoad: (state, action) => {
      state.componentInitialLoad = action.payload;
    },
    clearHarvestData: (state) => {
      state.initialState = true;
      state.componentInitialLoad = true;
      state.loading = false;
      state.harvestList = {};
      state.componentList = {};
    },
    clearHarvestLoadingState: (state) => {
      state.initialState = true;
      state.componentInitialLoad = true;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // upload new harvest list
      .addCase(uploadHarvestList.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadHarvestList.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(uploadHarvestList.rejected, (state, action) => {
        state.loading = false;
        state.harvestList = {};
      })
      // harvest list
      .addCase(getHarvestList.pending, (state) => {
        state.loading = true;
        // state.harvestList = {};
      })
      .addCase(getHarvestList.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.harvestList = action.payload?.data;
      })
      .addCase(getHarvestList.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
      })
      // harvest list
      .addCase(getHarvestListComponents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHarvestListComponents.fulfilled, (state, action) => {
        state.loading = false;
        state.componentInitialLoad = false;
        state.componentList = action.payload?.data;
      })
      .addCase(getHarvestListComponents.rejected, (state) => {
        state.loading = false;
        state.componentInitialLoad = false;
      });
  },
});

export const {
  setInitialLoad,
  clearHarvestData,
  setComponentInitialLoad,
  clearHarvestLoadingState,
} = harvestSlice.actions;
export default harvestSlice.reducer;
