import axiosConfig from "../../../../config/axiosConfig";
import { PIECE_PART_URL } from "../../../../config/config";
import { createQueryParams } from "../../../../utils/helper";

export const GetPiecePartService = (params) => {
  const { page, per_page, threshold, tiers, from_date, to_date, order_by } =
    params;

  const paramsList = {
    from_date: from_date,
    to_date: to_date,
    page: page,
    per_page: per_page,
    tier: tiers,
    threshold: threshold,
    order_by: order_by,
  };
  const queryString = createQueryParams(paramsList);
  const url = `${PIECE_PART_URL}${queryString ? `?${queryString}` : ""}`;

  return axiosConfig.get(url);
};
