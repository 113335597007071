import { createSlice } from "@reduxjs/toolkit";
import {
  addColumnPreferences,
  getAircraftFamiliy,
  getContants,
  getEngineFamily,
  getEngineFamilyTypes,
  getFilterDropdown,
  getGeneralColumnPreferences,
  upDateColumnPreferences,
} from "./commonActions";

export const initialState = {
  loading: false,
  addLoading: false,
  filterDropdownValues: {},
  columnPreferences: {},
  constanst: {},
  airCraftFamiliy: {},
  engineFamily: {},
  engineFamilyTypes: {},
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    clearCommonState: (state) => {
      state.loading = false;
      state.filterDropdownValues = {};
      state.constanst = {};
      state.engineFamily = {};
      state.engineFamilyTypes = {};
    },
  },
  extraReducers: (builder) => {
    builder

      // get filter dropdown
      .addCase(getFilterDropdown.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFilterDropdown.fulfilled, (state, action) => {
        state.loading = false;
        state.filterDropdownValues = action.payload?.data;
      })
      .addCase(getFilterDropdown.rejected, (state, action) => {
        state.loading = false;
      })

      // General Column Preferences
      .addCase(getGeneralColumnPreferences.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGeneralColumnPreferences.fulfilled, (state, action) => {
        state.loading = false;

        state.columnPreferences = action.payload.result;
      })
      .addCase(getGeneralColumnPreferences.rejected, (state) => {
        state.loading = false;
      })
      // ADD Column Preferences
      .addCase(addColumnPreferences.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addColumnPreferences.fulfilled, (state, action) => {
        state.addLoading = false;

        state.columnPreferences = action.payload.result;
      })
      .addCase(addColumnPreferences.rejected, (state) => {
        state.addLoading = false;
      })

      // Update Column Preferences
      .addCase(upDateColumnPreferences.pending, (state) => {
        state.loading = true;
      })
      .addCase(upDateColumnPreferences.fulfilled, (state, action) => {
        state.loading = false;
        state.columnPreferences = action.payload.result.column_preferences;
      })
      .addCase(upDateColumnPreferences.rejected, (state) => {
        state.loading = false;
      })

      // get constants
      .addCase(getContants.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContants.fulfilled, (state, action) => {
        state.loading = false;
        state.constanst = action.payload?.data;
      })
      .addCase(getContants.rejected, (state) => {
        state.loading = false;
      })
      // get air craft family
      .addCase(getAircraftFamiliy.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAircraftFamiliy.fulfilled, (state, action) => {
        state.loading = false;
        state.airCraftFamiliy = action.payload?.data;
      })
      .addCase(getAircraftFamiliy.rejected, (state) => {
        state.loading = false;
      })
      // get engine familty
      .addCase(getEngineFamily.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEngineFamily.fulfilled, (state, action) => {
        state.loading = false;
        state.engineFamily = action.payload?.data;
      })
      .addCase(getEngineFamily.rejected, (state) => {
        state.loading = false;
      })
      // get engine family types
      .addCase(getEngineFamilyTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEngineFamilyTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.engineFamilyTypes = action.payload?.data;
      })
      .addCase(getEngineFamilyTypes.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { clearCommonState } = commonSlice.actions;
export default commonSlice.reducer;
