import { Info } from "@mui/icons-material";

export const EMAIL = "email";
export const PASSWORD = "password";
export const OLD_PASSWORD = "old_password";
export const NEW_PASSWORD = "new_password";
export const CONFIRM_PASSWORD = "confirm_password";

export const pending = "Pending";
export const in_progress = "in_progress";
export const active = "Active";
export const inactive = "Inactive";

export const NAME = "name";
export const ROLE_NAME = "role_name";
export const MOBILE = "mobile_number";
export const DESIGNATION = "designation";
export const DEPARTMENT = "department";
export const ACCESS = "roles";
export const PROFILE_TYPE = "profile_type";
export const UPDATED_ROLE = "updated_role";
export const STATUS = "status";
// features
export const HOME = "Home";
export const PIPELINE = "Pipelines";
export const INVENTORY = "Inventory";
export const VENDORS = "Vendors Management";
export const ANALYTICS = "Analytics";
export const REPORTS = "Reports";
export const USERS = "Users";
export const ROLES = "Roles";
export const ALERTS = "Alerts";
export const FACTORYPARTS = "Factory Parts";

// Graph

export const GRAPH_ROLES = "roles";
export const GRAPH_TITLE = "title";
export const GRAPH_ORDER = "order";
export const GRAPH_TYPE = "type";
export const GRAPH_SQL_QUERY = "sql_query";
export const GRAPH_STATUS = "status";
export const GRAPH_CONFIG_JSON = "config_json";
export const GRAPH_QUERY_JSON = "query_json";

// Alert
export const ALERT_TYPE = "alert_type";
export const FREQUENCY = "frequency";
export const START_DATE = "start_date";
export const END_DATE = "end_date";
export const EMAIL_ID = "email_id";
export const ALERT_STATUS = "status";
export const DAY_OF_WEEK = "day_of_week";
export const TIME_OF_DAY = "time_of_day";
export const REPORT_FORMAT = "report_format";
export const CREATED_BY = "created_by";

// stautus

export const SUCCESS = "SUCCESS";
export const FAIL = "FAIL";

export const accessor = "accessorKey";

export const list = "list";
export const chart = "chart";
export const tooltTitle = {
  edit: "Edit",
  delete: "Delete",
  view: "View",
  resend_activ_link: "Resend Activation Link",
  history: "History",
  import: "Import",
  download: "Download",
  info: "Supported Documents ",
};

// pipeline

export const SELLER = "seller";
export const OP_REGION = "operating_region";
export const ASSET_LOC = "location_of_asset";
export const ASK_PRICE = "ask_price";
export const EST_PRICE = "estimated_price";
export const LAST_OP = "last_operator";
export const CONDITION = "condition";
export const STRATERGY = "exit_strategy";
export const DUE_DATE = "bid_due_date";
export const FST_BID_DATE = "first_round_bid_date";
export const BAFO = "bafo_bid_date";
export const SENT = "sent_by";

export const TYPE = "type";
export const DESCRIPTION = "description";
// asset

export const MSN = "msn_esn";
export const ESN = "msn_esn";
export const AIR_DOM = "aircraft_dom";
export const AIR_OP_REGION = "operating_region";
export const AIR_FAMILY = "aircraft_family";
export const ENG_FAMILY = "engine_family";
export const AIR_LAST_OP = "last_operator";
export const AIR_TYPE = "aircraft_type";
export const ENG_TYPE = "engine_type";
export const CLP_TYPE = "clp";
export const AIR_LOCATION = "location_of_asset";
export const AIR_CONDITION = "condition";
export const ASSET_TYPE = "type";
export const ESN_MONTHS = "months";
export const FC_PER_MONTH = "fc_per_month";
export const LEASE_TERM = "lease_term";
export const LEASE_CYCLE = "lease_cycles";

export const MODEL = "model";
export const PARTS = "parts";

// EXPORT Fn ==>Add-Aircraft
 
export const AIRCRAFT = "aircraft";
export const FACTORY = "factory";
export const SAMPLE_LLP = "sample-llp";
export const ENGINE_MASTER_DATA = "engine-master-data";
export const ENGINE_FAMILY = "engine_family_id";