import {
  GET_HARVEST_LIST_COMPONENTS_TYPE,
  GET_HARVEST_LIST_TYPE,
  UPLOAD_HARVEST_LIST_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  GetHarvestListComponenetsService,
  GetHarvestListService,
  UploadHarvestListService,
} from "./harvestListServices";

export const uploadHarvestList = createAppAsyncThunk(
  UPLOAD_HARVEST_LIST_TYPE,
  UploadHarvestListService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const getHarvestList = createAppAsyncThunk(
  GET_HARVEST_LIST_TYPE,
  GetHarvestListService,
  {
    errorMessage: { show: true },
  }
);

export const getHarvestListComponents = createAppAsyncThunk(
  GET_HARVEST_LIST_COMPONENTS_TYPE,
  GetHarvestListComponenetsService,
  {
    errorMessage: { show: true },
  }
);
