import { createSlice } from "@reduxjs/toolkit";
import {
  getInventoryCheck,
  getAircraftInventoryCheckSummary,
} from "./inventoryCheckActions";

export const initialState = {
  isInitialLoad: true,
  loading: false,
  inventoryCheckData: {},
  summaryData: {},
};

const inventoryCheckSlice = createSlice({
  name: "inventoryCheck",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    clearInventoryCheckdata: (state) => {
      state.isInitialLoad = true;
      state.loading = false;
      state.inventoryCheckData = {};
      state.summaryData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // inventory check list
      .addCase(getInventoryCheck.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInventoryCheck.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.inventoryCheckData = action.payload?.data;
      })
      .addCase(getInventoryCheck.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
      })
      // inventory summary list
      .addCase(getAircraftInventoryCheckSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAircraftInventoryCheckSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.summaryData = action.payload?.data;
      })
      .addCase(getAircraftInventoryCheckSummary.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setInitialLoad, clearInventoryCheckdata } =
  inventoryCheckSlice.actions;
export default inventoryCheckSlice.reducer;
