import moment from "moment";
import axiosConfig from "../../../config/axiosConfig";
import {
  GET_IN_REPAIR_INVENTORIES_URL,
  GET_INVENTORIES_COUNTRY_NAME_TOTAL_QTY_GRAPH_URL,
  GET_INVENTORIES_PART_TYPE_TOTAL_QTY_GARPH_URL,
  GET_INVENTORY_FILTER_URL,
  GET_INVENTORY_PART_CLASS_TOTAL_QTY_GRAPH_URL,
  GET_INVENTORY_STOCK_SUMMARY_DETAILS_URL,
  GET_INVENTORY_TIER_GRAPH_COUNT_GRAPH_URL,
  GET_INVENTORY_URL,
  UNDER_STOCK_INVENTORIES_URL,
} from "../../../config/config";
import { createQueryParams } from "../../../utils/helper";

export const GetInventoriesService = (params) => {
  const {
    page,
    per_page,
    manufacturer,
    qty_available,
    tier,
    from_date,
    to_date,
    status,
    ata_chapter,
    serial_number,
    pn_type,
    pn,
    order_by,
  } = params;
  // filter_key: tier,qty_available,pnm_auto_key__manufacturer
  const paramsList = {
    page,
    per_page,
    from_date: from_date
      ? from_date
      : moment().subtract(18, "years").format("YYYY-MM-DD"),
    to_date: to_date ? to_date : moment().format("YYYY-MM-DD"),
    tier: tier,
    qty_available: qty_available,
    pnm_auto_key__manufacturer: manufacturer,
    status: status,
    ata_chapter,
    serial_number,
    pn_type: pn_type,
    pn,
    order_by,
  };
  const queryString = createQueryParams(paramsList);
  const url = `${GET_INVENTORY_URL}${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

export const GetInventoriesFilterService = (params) => {
  const { filed } = params;
  return axiosConfig.get(`${GET_INVENTORY_FILTER_URL}?filter_key=${filed}`);
};

export const GetInRepairInventoriesService = (params) => {
  const {
    page,
    per_page,
    manufacturer,
    qty_available,
    tier,
    from_date,
    to_date,
    status,
    ata_chapter,
    serial_number,
    pn_type,
    order_by,
  } = params;

  // Prepare the parameters for the query string (InRepair version)
  const paramsList = {
    page,
    per_page,
    from_date: from_date
      ? from_date
      : moment().subtract(18, "years").format("YYYY-MM-DD"),
    to_date: to_date ? to_date : moment().format("YYYY-MM-DD"),
    tier: tier,
    qty_available: qty_available,
    pnm_auto_key__manufacturer: manufacturer,
    status: status,
    pn_type: pn_type,
    ata_chapter,
    serial_number,
    order_by,
  };

  // Create the query string for the 'InRepair' inventories
  const queryString = createQueryParams(paramsList);
  const url = `${GET_IN_REPAIR_INVENTORIES_URL}${queryString ? `?${queryString}` : ""}`;

  return axiosConfig.get(url);
};

export const GetUnderStockInventoriesService = (params) => {
  const {
    page,
    per_page,
    manufacturer,
    qty_available,
    tier,
    from_date,
    to_date,
    status,
    ata_chapter,
    serial_number,
    pn_type,
    order_by,
    under_stock_threshold,
  } = params;

  // Prepare the parameters for the query string (InRepair version)
  const paramsList = {
    page,
    per_page,
    from_date: from_date
      ? from_date
      : moment().subtract(18, "years").format("YYYY-MM-DD"),
    to_date: to_date ? to_date : moment().format("YYYY-MM-DD"),
    tier: tier,
    qty_available: qty_available,
    pnm_auto_key__manufacturer: manufacturer,
    status: status,
    pn_type: pn_type,
    ata_chapter,
    serial_number,
    order_by,
    under_stock_threshold,
  };

  // Create the query string for the 'InRepair' inventories
  const queryString = createQueryParams(paramsList);
  const url = `${UNDER_STOCK_INVENTORIES_URL}${queryString ? `?${queryString}` : ""}`;

  return axiosConfig.get(url);
};

export const GetInventoryStockSummaryDetailsService = (params) => {
  const { page, per_page, from_date, to_date, pnm_auto_key, type } = params;
  // filter_key: tier,qty_available,pnm_auto_key__manufacturer
  const paramsList = {
    page,
    per_page,
    from_date: from_date
      ? from_date
      : moment().subtract(18, "years").format("YYYY-MM-DD"),
    to_date: to_date ? to_date : moment().format("YYYY-MM-DD"),
    pnm_auto_key: pnm_auto_key,
    type: type,
  };
  const queryString = createQueryParams(paramsList);
  const url = `${GET_INVENTORY_STOCK_SUMMARY_DETAILS_URL}${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

export const GetInventoryCountryNameTotalQtyGraphService = (data) => {
  return axiosConfig.get(
    GET_INVENTORIES_COUNTRY_NAME_TOTAL_QTY_GRAPH_URL,
    data
  );
};

export const GetInventoryPartClassTotalQtyGraphService = (data) => {
  return axiosConfig.get(GET_INVENTORY_PART_CLASS_TOTAL_QTY_GRAPH_URL, data);
};

export const GetInventoryTierGraphCountGraphService = (data) => {
  return axiosConfig.get(GET_INVENTORY_TIER_GRAPH_COUNT_GRAPH_URL, data);
};

export const GetInventoryPartTypeTotalQtyGraphService = (data) => {
  return axiosConfig.get(GET_INVENTORIES_PART_TYPE_TOTAL_QTY_GARPH_URL, data);
};
