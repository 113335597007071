import axiosConfig from "../../../config/axiosConfig";
import { EBITA_URL } from "../../../config/config";
import { createQueryParams } from "../../../utils/helper";

export const GetEbitaService = (params) => {
  try {
    const { project_assets_slug } = params || {};

    const paramsList = {
      project_assets_slug,
    };

    const queryString = createQueryParams(paramsList);
    const url = `${EBITA_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};
