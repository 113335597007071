import {
  GET_INVENTORY_CHECK_SUMMARY_TYPE,
  GET_INVENTORY_CHECK_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  GetInventoryCheckService,
  GetAircraftInventoryCheckSummaryService,
} from "./inventoryCheckServices";

export const getInventoryCheck = createAppAsyncThunk(
  GET_INVENTORY_CHECK_TYPE,
  GetInventoryCheckService,
  {
    errorMessage: { show: true },
  }
);

export const getAircraftInventoryCheckSummary = createAppAsyncThunk(
  GET_INVENTORY_CHECK_SUMMARY_TYPE,
  GetAircraftInventoryCheckSummaryService,
  {
    errorMessage: { show: true },
  }
);
