import axiosConfig from "../../../config/axiosConfig";
import { PRICING_URL } from "../../../config/config";
import { createQueryParams } from "../../../utils/helper";

export const GetPricingService = (params) => {
  try {
    const { page, per_page, ordering, type, slug, comp_type } = params || {};

    const paramsList = {
      page,
      per_page,
      ordering,
    };

    const queryString = createQueryParams(paramsList);

    const url = comp_type
      ? `${PRICING_URL}/${type}/${slug}/${comp_type}${queryString ? `?${queryString}` : ""}`
      : `${PRICING_URL}/${type}/${slug}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetPricingSummaryService = (params) => {
  try {
    const { type, slug, rp } = params || {};
    const paramsList = {
      repair_percent: rp,
    };
    const queryString = createQueryParams(paramsList);
    const url = `${PRICING_URL}/${type}/${slug}/summary${queryString ? `?${queryString}` : ""}`;
    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const UpdatePricingService = (params) => {
  try {
    const { update, type, slug, rp } = params || {};

    const paramsList = {
      update,
    };

    const queryString = createQueryParams(paramsList);

    const url = `${PRICING_URL}/${type}/${slug}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.patch(url, { repair_percent: rp });
  } catch (error) {
    throw error;
  }
};
