import {
  ADD_COLUMN_PREFERENCES_TYPE,
  GET__GENERAL_GENCOLUMN_PREFERENCES_TYPE,
  GET_AIRCRAFT_FAMILIES_TYPE,
  GET_CONSTANTS_TYPE,
  GET_ENGINE_FAMILIES_TYPE,
  GET_ENGINE_TYPE_TYPE,
  GET_FILTER_DROPDOWN_TYPE,
  UPDATE_COLUMN_PREFERENCES_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  AddColumnPreferencesService,
  GetAircraftFamilyService,
  GetConstantsService,
  GetEngineFamilyService,
  GetEngineFamilyTypesService,
  GetFilterDropdownService,
  GetGeneralColumnPreferencesService,
  UpdateColumnPreferencesService,
} from "./commonService";

export const getFilterDropdown = createAppAsyncThunk(
  GET_FILTER_DROPDOWN_TYPE,
  GetFilterDropdownService,
  {}
);

export const getGeneralColumnPreferences = createAppAsyncThunk(
  GET__GENERAL_GENCOLUMN_PREFERENCES_TYPE,
  GetGeneralColumnPreferencesService
);

// Add addColumnPreferences
export const addColumnPreferences = createAppAsyncThunk(
  ADD_COLUMN_PREFERENCES_TYPE,
  AddColumnPreferencesService
);
// Update column preferences
export const upDateColumnPreferences = createAppAsyncThunk(
  UPDATE_COLUMN_PREFERENCES_TYPE,
  UpdateColumnPreferencesService
);

// getconstants

export const getContants = createAppAsyncThunk(
  GET_CONSTANTS_TYPE,
  GetConstantsService
);

export const getAircraftFamiliy = createAppAsyncThunk(
  GET_AIRCRAFT_FAMILIES_TYPE,
  GetAircraftFamilyService
);

export const getEngineFamily = createAppAsyncThunk(
  GET_ENGINE_FAMILIES_TYPE,
  GetEngineFamilyService
);

export const getEngineFamilyTypes = createAppAsyncThunk(
  GET_ENGINE_TYPE_TYPE,
  GetEngineFamilyTypesService
);
