// src/store/reducers/rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../redux/features/auth/authSlice";
import settingsReducer from "../redux/features/settings/settingsSlice";
import inventoriesReducer from "../redux/features/inventories/inventoriesSlice";
import vendorReducer from "../redux/features/vendors-management/vendorsManagementSlice";
import commonReducer from "../redux/features/common/commonSlice";
import projectReducer from "../redux/features/project/projectSlice";
import purchaseReducer from "../redux/features/inventories/purchase/purchaseSlice";
import harvestReducer from "../redux/features/harvest-list/harvestListSlice";
import inventoryCheckReducer from "../redux/features/inventory-check/inventoryCheckSlice";
import pricingReducer from "../redux/features/pricing/pricingSlice";
import cashFlowSliceReducer from "../redux/features/cash-flow/cashFlowSlice";
import ebitaSliceReducer from "../redux/features/ebita/ebitaSlice";

export const persistConfig = {
  storage,
  key: "auth",
  whitelist: ["user", "defaultPath"],
};

const persistAuthReducer = persistReducer(persistConfig, authReducer);

// Combine all reducers
const reducers = {
  auth: persistAuthReducer,
  setting: settingsReducer,
  inventories: inventoriesReducer,
  vendors: vendorReducer,
  common: commonReducer,
  project: projectReducer,
  purchase: purchaseReducer,
  harvest: harvestReducer,
  inventoryCheck: inventoryCheckReducer,
  pricing: pricingReducer,
  cashFlow: cashFlowSliceReducer,
  ebita: ebitaSliceReducer,
};

const combinedReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  // Handle logout action to clear all state
  if (action.type === "auth/logout") {
    // Preserve some state values if needed
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
