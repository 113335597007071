  import { GET_EBITA_TYPE } from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import { GetEbitaService } from "./ebitaServices";
  

  
  export const getEbita = createAppAsyncThunk(
    GET_EBITA_TYPE,
    GetEbitaService,
    {
      errorMessage: { show: true },
    }
  );
  
 
  