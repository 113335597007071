import axiosConfig from "../../../config/axiosConfig";
import { INVENTORY_CHECK_URL } from "../../../config/config";
import { createQueryParams } from "../../../utils/helper";

export const GetInventoryCheckService = (params) => {
  try {
    const { page, per_page, ordering, slug, type, summary, comp_type } =
      params || {};

    const paramsList = {
      page,
      per_page,
      ordering,
      summary,
    };
    const queryString = createQueryParams(paramsList);

    const url = comp_type
      ? `${INVENTORY_CHECK_URL}/${type}/${slug}/${comp_type}${queryString ? `?${queryString}` : ""}`
      : `${INVENTORY_CHECK_URL}/${type}/${slug}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetAircraftInventoryCheckSummaryService = (params) => {
  try {
    const { slug, type } = params || {};

    const url = `${INVENTORY_CHECK_URL}/${type}/${slug}/summary`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};
