import axiosConfig from "../../../config/axiosConfig";
import {
  ALTERNATE_ROLE_URL,
  CHANGE_PASSWORD_URL,
  GET_AUDIT_LOG_FOR_ROLE_URL,
  GET_AUDIT_LOG_FOR_USER_URL,
  GET_FEATURES_URL,
  ROLES_URL,
  TEMPLATE_URL,
  USER_PROFILE_URL,
  USERS_URL,
  ANALYTICS_GRAPHS_URL,
  ALERT_URL,
  AIRCRAFT_MASTER_DATA_URL,
  GET_AUDIT_LOG_FOR_ALERT,
  GET_TRIGGER_ALERT,
  IMPORT_ENGINE_MASTER_DATA_URL,
  GET_AIRCRAFT_FAMILY,
  CREATE_AIRCRAFT_FAMILY_URL,
  ENGINE_FAMILY_URL,
  UPDATE_AIRCRAFT_FAMILY_URL,
  DELETE_AIRCRAFT_FAMILY_URL,
} from "../../../config/config";
import { createQueryParams, getStringFromArray } from "../../../utils/helper";

export const GetUserService = (params) => {
  try {
    const {
      page,
      per_page,
      name,
      email,
      designation,
      department,
      role = [],
      status = [],
      ordering,
    } = params || {};

    const paramsList = {
      page,
      per_page,
      name,
      email,
      designation,
      department,
      roles: getStringFromArray(role),
      status: getStringFromArray(status),
      ordering,
    };

    const queryString = createQueryParams(paramsList);

    const url = `${USERS_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetAnalyticsGraphsService = (params) => {
  try {
    const { page, per_page, ordering } = params || {};

    const paramsList = {
      page,
      per_page,
      ordering,
    };

    const queryString = createQueryParams(paramsList);
    const url = `${ANALYTICS_GRAPHS_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const AddAnalyticsGraphService = (data) => {
  return axiosConfig.post(ANALYTICS_GRAPHS_URL, data);
};

export const UpdateAnalyticsGraphService = (data) => {
  // Extract id from data and remove it from the payload
  const { id, ...payload } = data;
  return axiosConfig.put(`${ANALYTICS_GRAPHS_URL}/${id}/`, payload);
};

export const DeleteAnalyticsGraphService = (id) => {
  return axiosConfig.delete(`${ANALYTICS_GRAPHS_URL}/${id}/`);
};

//   ALERT

export const GetAlertService = (params) => {
  try {
    const {
      page,
      per_page,
      report_format,
      day_of_week,
      alert_type,
      start_date,
      end_date,
      email_id,
      time_of_day,
      frequency,
      ordering,
    } = params || {};

    const paramsList = {
      page,
      per_page,
      alert_type,
      start_date,
      end_date,
      frequency,
      email_id,
      report_format,
      day_of_week,
      time_of_day,
      ordering,
    };

    const queryString = createQueryParams(paramsList);
    const url = `${ALERT_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetSentHistoryAlert = (params) => {
  console.log(params, "PARAMS")
  const { alert_id } = params || {};
  const data = createQueryParams({ alert_id }); 
  return axiosConfig.get(`${GET_TRIGGER_ALERT}?${data}`)  
};

export const AddAlertService = (data) => {
  return axiosConfig.post(ALERT_URL, data);
};

export const UpdateAlertService = async (data) => {
  // const {id} = data
  const response = await axiosConfig.patch(`${ALERT_URL}`, data);
  return response.data;
};

export const DeleteAlertService = (slug) => {
  return axiosConfig.delete(`${ALERT_URL}/${slug}`);
};

export const GetAuditLogAlertService = (id) => {
  return axiosConfig.get(`${GET_AUDIT_LOG_FOR_ALERT}/${id}/Alerts`);
};

// Aircraft- Family

export const GetAircraftFamilyService = (data) => {
  return axiosConfig.get(`${GET_AIRCRAFT_FAMILY}`,data);
};

// AirFam POST

export const AddAircraftFamilyService = async (data) => {
  try {
    const response = await axiosConfig.post(`${CREATE_AIRCRAFT_FAMILY_URL}/`, data);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};
// PATCH

export const UpdateAircraftFamilyService = async (payload) => {
  const { id, data } = payload;
  const response = await axiosConfig.patch(`config/families/${id}/update_aircraft_family/`, {
    name: data.name
  });
  return response.data; // Return the response data
};

// DELETE

export const DeleteAircraftFamilyService = (id) => {
  return axiosConfig.delete(`config/families/${id}/delete_aircraft_family/`);
};

// Engine Family

export const GetEngineFamilyService = (data) => {
  return axiosConfig.get(`${ENGINE_FAMILY_URL}/${data.paramsname}`,data);
};

export const AddEngineFamilyService = (data)=>{
  return axiosConfig.post(`${ENGINE_FAMILY_URL}${data?.paramname}/`,data)
}

export const UpdateEngineFamily = (payload) => {
  const { id, data, paramname } = payload;
  return axiosConfig.patch(`${ENGINE_FAMILY_URL}${id}/${paramname}/`, data);
};

export const DeleteEngineFamily = (data) => {
  return axiosConfig.delete(`${ENGINE_FAMILY_URL}/${data?.id}/${data.paramname}/`);
};


// User

export const AddUserService = (data) => {
  return axiosConfig.post(USERS_URL, data);
};

export const UpdateUserService = (data) => {
  return axiosConfig.patch(USERS_URL, data);
};

export const DeleteUserService = (data) => {
  return axiosConfig.delete(`${USERS_URL}/${data?.id}`, data);
};

export const AddRoleService = (data) => {
  return axiosConfig.post(ROLES_URL, data);
};

export const AddTemplateService = ({ data, headers }) => {
  return axiosConfig.post(TEMPLATE_URL, data, { headers });
};

export const UpdateTemplatetService = ({ data, headers }) => {
  return axiosConfig.patch(`${TEMPLATE_URL}`, data, { headers });
};

export const GetTemplateService = (params) => {
  try {
    const { page, per_page, ordering, name } = params || {};

    const paramsList = {
      page,
      per_page,
      name,
      ordering,
    };

    const queryString = createQueryParams(paramsList);

    const url = `${TEMPLATE_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const DeleteTemplateService = ({ id }) => {
  return axiosConfig.delete(`${TEMPLATE_URL}/${id}`);
};

export const UpdateRoleService = (data) => {
  return axiosConfig.patch(ROLES_URL, data);
};

export const DeleteRoleService = (data) => {
  return axiosConfig.delete(`${ROLES_URL}/${data?.id}`, data);
};

export const AlternateRoleService = (data) => {
  return axiosConfig.post(ALTERNATE_ROLE_URL, data);
};

export const GetRolesService = (params) => {
  const { page, per_page, role_name, profile_type, created_by, ordering } =
    params;

  const paramsList = {
    page,
    per_page,
    role_name,
    profile_type,
    created_by: getStringFromArray(created_by),
    ordering,
  };
  const queryString = createQueryParams(paramsList);
  const url = `${ROLES_URL}${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

export const ChangePasswordService = (data) => {
  return axiosConfig.post(CHANGE_PASSWORD_URL, data);
};

export const GetFeaturesService = (params) => {
  const { page, per_page } = params;
  return axiosConfig.get(
    `${GET_FEATURES_URL}?page=${page}&per_page=${per_page}`
  );
};

export const updateUserProfileService = ({ data, headers }) => {
  return axiosConfig.patch(USER_PROFILE_URL, data, { headers });
};

export const GetAuditLogUserService = (id) => {
  return axiosConfig.get(`${GET_AUDIT_LOG_FOR_USER_URL}/${id}/Users`);
};

export const GetAuditLogRoleService = (id) => {
  return axiosConfig.get(`${GET_AUDIT_LOG_FOR_ROLE_URL}/${id}/Roles`);
};

// master data

export const AddAircraftMasterDataService = (data) => {
  return axiosConfig.post(AIRCRAFT_MASTER_DATA_URL, data);
};

export const AddeEngineMasterDataService = (data) => {
  return axiosConfig.post(IMPORT_ENGINE_MASTER_DATA_URL, data);
};

export const GetAircraftMasterDataService = (params) => {
  try {
    const { page, per_page, types, family, ordering } = params || {};

    const paramsList = {
      page,
      per_page,
      ordering,
      aircraft_type: getStringFromArray(types),
      aircraft_family: getStringFromArray(family),
    };

    const queryString = createQueryParams(paramsList);

    const url = `${AIRCRAFT_MASTER_DATA_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const DeleteMasterDataRecord = (data) => {
  return axiosConfig.delete(`${AIRCRAFT_MASTER_DATA_URL}/${data?.id}`, data);
};