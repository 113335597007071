import {
  ADD_ASSET_TYPE,
  ADD_PROJECTS_TYPE,
  DELETE_ASSET_TYPE,
  DELETE_PROJECTS_TYPE,
  GET_ASSET_TYPE,
  GET_PROJECTS_TYPE,
  UPDATE_ASSET_TYPE,
  UPDATE_PROJECTS_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  AddAssetService,
  AddProjectService,
  DeleteAssetService,
  DeleteProjectService,
  GetAssetService,
  GetProjectService,
  UpdateAssetService,
  UpdateProjectService,
} from "./projectServices";

// projects
export const getProjects = createAppAsyncThunk(
  GET_PROJECTS_TYPE,
  GetProjectService,
  {}
);

export const addProjects = createAppAsyncThunk(
  ADD_PROJECTS_TYPE,
  AddProjectService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const updateProjects = createAppAsyncThunk(
  UPDATE_PROJECTS_TYPE,
  UpdateProjectService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteProjects = createAppAsyncThunk(
  DELETE_PROJECTS_TYPE,
  DeleteProjectService,
  {
    successMessage: {
      show: true,
      message: (response) => `Project deleted successfully`,
    },
    errorMessage: { show: true },
  }
);

// assets

export const getAssets = createAppAsyncThunk(
  GET_ASSET_TYPE,
  GetAssetService,
  {}
);

export const addAsset = createAppAsyncThunk(ADD_ASSET_TYPE, AddAssetService, {
  successMessage: { show: true },
  errorMessage: { show: true },
});

export const updateAsset = createAppAsyncThunk(
  UPDATE_ASSET_TYPE,
  UpdateAssetService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteAssets = createAppAsyncThunk(
  DELETE_ASSET_TYPE,
  DeleteAssetService,
  {
    successMessage: {
      show: true,
      message: (response) => `Asset deleted successfully`,
    },
    errorMessage: { show: true },
  }
);
