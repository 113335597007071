import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Global, css } from "@emotion/react";

// Define your CSS variables
const cssVariables = css`
  :root {
    --font-thin: 100;
    --font-extralight: 200;
    --font-light: 300;
    --font-regular: 400;
    --font-medium: 500;
    --font-semibold: 600;
    --font-bold: 700;
    --font-extrabold: 800;
    --font-black: 900;
    --font-size-11: 11px;
    --font-size-13: 13px;
    --font-size-13: 13px;
    --font-size-15: 15px;
    --font-size-16: 16px;
    --font-size-18: 18px;
    --font-size-22: 22px;
    --font-size-100: 100px;
    --primary-color: #000000;
    --secondary-color: #ffffff;
    --placeholder-color: #00000080;
    --inactive-link: #000000b2;
    --whisper-color: #f3f0f8;
    --alabaster-color: #fafafa;
    --wildsand-color: #f5f5f5;
    --silver-color: #c4c4c4;
    --bondiblue-color: #00a2b4;
    --blue-color: #0019ff;
    --alto-color: #d7d7d7;
    --blackhaze-color: #f3f4f4;
    --iron-color: #d9d9d9;
    --emerald-color: #58cb7f;
    --seagreen-color: #34a058;
    --tumeric-color: #bec145;
    --gold-color: #c5a501;
    --copper-color: #b37329;
    --brown-color: #945000;
    --crimson-color: #da0e1b;
    --blue-color: #0019ff;
    --red-color: #d32f2f;
    --gorse-color: #fef445;
    --grey-color: #808080s;
    --disable-color: rgba(0, 0, 0, 0.38);
  }
`;

// Create a custom theme
const theme = createTheme({
  typography: {
    fontFamily: "TT Hoves Pro Trial",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body, #root {
          height: 100%;
          margin: 0;
          font-family: "TT Hoves Pro Trial"
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }
      `,
    },
  },
});

// ThemeConfig component
const ThemeConfig = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Global styles={cssVariables} />
      {children}
    </ThemeProvider>
  );
};

export default ThemeConfig;
